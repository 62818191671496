*,
*:before,
*:after {
    box-sizing: border-box;
    margin: auto;
}

/*-- Background Color Start --*/

.bg-color-one {
    background: url('../images/background/background_img.jpg') left center;
}

.bg-color-two {
    background: url('../images/background/background_img.jpg') left center;
}

/*-- Background Color End --*/

/* -- Company Logo Start --*/
.company-logo {
    width: 100px !important;
    height: 100%;
}

@media (min-width:600px) {
    .company-logo {
        width: 50%;
        height: 50%;
    }
}

/* -- Company Logo Ends --*/

/* -- Footer Start --*/
footer {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-flow: row wrap;
    padding: 30px 50px;
    color: #fff;

}
.footer-top-left-logo{
    margin-bottom: 2em;
}
.footer-top-left-logo img{
    width:125px;
    mix-blend-mode: multiply;
    float: right;
}
.media-links {
    width: 50%;
}
.media-links a{
    color: #333;
}

.footer>* {
    flex: 1 100%;
}

.footer-left {
    margin-right: 1em;
    margin-bottom: 2em;
    margin-top: -0.5em;
    margin-left: 1em;
}

.footer-left img {
    width: 125px;
    mix-blend-mode: multiply;
}

.footer-right h2 {
    font-weight: 600;
    font-size: 17px;
    color:#333;
}

.footer ul {
    list-style: none;
    padding-left: 0;
}

.footer li {
    line-height: 2em;
    margin: 0;
}

.footer a {
    text-decoration: none;
}

.footer-right {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-flow: row wrap;
    margin-left: 1rem;
}

.footer-right>* {
    flex: 4;
    margin-right: 1.5em;
}

.box a {
    color: #999;
}

.box a span {
    color: #999;
    font-size: 13px;
}

.footer-bottom {
    text-align: left;
    color: #FFF;
    font-family: 'Outfit';
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient(152deg, #00558C 0%, #FCE302 100%);
    height: 67px;
    padding: 1.5em 5em;
    width: 100%;
}

.our-brands {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
}

.our-brands h4 {
    font-size: 25px;
    font-weight: 400;
    align-items: flex-start;
    color: #000;
}

.our-brands img {
    width: 120px;
    align-items: center;
}
@media screen and (max-width:768px) {
    /* footer {
        padding: 7px 0px;
    } */

    .footer>* {
        flex: 1 100%;
    }

    .footer-left {
        margin-right: 1em;
        margin-bottom: 2em;
        margin-left: 1em;
    }

    .footer-left img {
        width: 120px;
        display: flex;
    }

    .footer-left span img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        width: 100px;
    }

    .footer-left h1 {
        font-size: 1rem;
        text-align: center;
    }

    .footer-right {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .footer-right h2 {
        font-weight: 600;
        font-size: 14px;
    }

    .footer ul {
        list-style: none;
        padding: 5px;
    }

    .footer li {
        line-height: 1em;
        margin: 0;
    }

    .footer a {
        text-decoration: none;
    }

    .footer-right>* {
        flex: 1 25%;
    }

    .box a {
        color: #999;
        font-size: 10px
    }

    .box a span {
        color: #999;
        font-size: 8px;
    }

    .address {
        line-height: 0.5em !important;
        font-size: 5px !important;
    }

    .footer-bottom p {
        font-size: 12px;
    }

    .footer-left p {
        padding-right: 20%;
        color: #999;
    }

    .our-brands {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
    }

    .our-brands h4 {
        font-size: 14px;
    }

    .our-brands img {
        width: 60px;
        align-items: center;
    }
}
@media screen and (max-width:480px) {
    footer {
        padding: 15px 0px;
    }

    .footer>* {
        flex: 1 100%;
    }

    .footer-left {
        margin-right: 1em;
        margin-bottom: 2em;
        margin-left: 1em;
    }

    .footer-left img {
        width: 120px;
        display: flex;
    }

    .footer-left span img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        width: 100px;
    }

    .footer-left h1 {
        font-size: 1rem;
        text-align: center;
    }

    .footer-right h2 {
        font-weight: 600;
        font-size: 17px;
    }

    .footer ul {
        list-style: none;
        padding-left: 10px;
    }

    .footer li {
        line-height: 1.5em;
        margin: 0;
    }

    .footer a {
        text-decoration: none;
    }

    .footer-right {
        margin-left: 2rem;
    }

    .footer-right>* {
        flex: 1 25%;
        margin-right: 0.8em;
    }

    .box a {
        color: #999;
        font-size: 12px
    }

    .box a span {
        color: #999;
        font-size: 13px;
    }
    .footer-bottom {
        max-width: 100%;
        height: 40px;
        padding: 0.8em 2em;
    }

    .footer-bottom p {
        font-size: 12px;
        max-width: 100%;
    }

    .footer-left p {
        padding-right: 20%;
        color: #999;
    }

    .our-brands {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
    }

    .our-brands h4 {
        font-size: 14px;
    }

    .our-brands img {
        width: 60px;
        align-items: center;
    }
}



/* -- Footer Ends --*/

/* -- Slider Starts --*/

.slider {
    width: 100%;
    height: 60vh;
    position: relative;
    overflow: hidden;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all .5s ease;
}

.slide img {
    height: 100%;
}

.current {
    opacity: 1;
    transform: translateX(0);
}

.content {
    position: absolute;
    top: 23rem;
    left: 40rem;
    width: 40%;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.3);
    animation: slide-up 1s ease .9s;
    animation-fill-mode: forwards;
    opacity: 0;
    /*visibility: hidden;*/
}

@keyframes slide-up {
    0% {
        visibility: visible;
        top: 17rem;
    }

    100% {
        visibility: visible;
        top: 5rem;

    }
}

.content>* {
    color: #fff;
    margin-bottom: 1rem;
}

.current .content {
    opacity: 1;
}

.arrow {
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: transparent;
    color: #fff;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    top: 35%;
    z-index: 999;
}

.arrow:hover {
    background: #fff;
    color: #777;
}

.next {
    right: 1.5rem;
}

.prev {
    left: 1.5rem;
}

.current-hr {
    height: 2px;
    background: #fff;
    width: 100%;
}

@media screen and (max-width:480px) {
    .slider {
        height: 20vh;
    }

    .slide {
        height: 100%;
    }

    .current {
        height: 60%;
    }

    .content {
        display: none;
    }

    .slide img {
        height: 60%;
    }
}

@media screen and (max-width:768px) {
    .slider {
        height: 30vh;
    }

    .slide {
        height: 100%;
    }

    .slide img {
        width: 100%;
        height: 60%;
    }

    .content {
        top: 2rem;
        left: 20rem;
        width: 40%;
        padding: 0.5rem;
    }

    .content h2 {
        font-size: 17px;
    }

    .content p {
        font-size: 13px;
    }
}


/* -- Slider Ends --*/

/* -- Product Category Starts --*/
.product-category-tab {
    border-bottom: 10px solid #f2f2f2;
}

.product-category-container {
    width: 90%;
    padding-right: auto;
    padding-left: auto;
    margin-right: auto;
    margin-left: auto;
}

.product-category {
    display: flex;
    flex-direction: row;
}

.product-category a {
    text-decoration: none !important;
   
}

.product-category img {
    max-width: 100px;
}

.features-grid {
    padding: 10px;
}

.features-grid h4 {
    text-transform: uppercase;
    color: #205170;
    font-family: 'Helvetica Neue Medium';
    margin: 0;
    font-size: 1.25rem;
}

.features-grid h4 span {
    display: block;
    font-size: 1rem;
    font-weight: 300;
    color: #000;
    opacity: 0.8;
    font-family: 'helveticaneuelight';
}

.features-grid a {
    text-decoration: none !important;
}

.vl {
    height: 100px;
    margin-left: 20px;
    top: 0;
}

.row-item {
    display: flex !important;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.img-mx-length {
    max-height: 200px;
    max-width: 150px;
}

@media screen and (max-width:480px) {
    .product-category-tab {
        border-bottom: 5px solid #f2f2f2;
    }

    .product-category-container {
        width: 100%;
    }

    .row-item {
        margin-right: -8px;
        margin-left: -8px;
    }

    .product-category a {
        text-decoration: none !important;
        padding: 10px !important;
    }

    .product-category a img {
        max-width: 70px;
    }

    .features-grid {
        padding: 5px;
    }

    .features-grid h4 {
        font-size: 1rem;
    }

    .features-grid h4 span {
        font-size: 0.8rem;
    }

    .vl {
        display: none;
    }
}

@media screen and (max-width:768px) {
    .product-category-tab {
        border-bottom: 5px solid #f2f2f2;
    }

    .product-category-container {
        width: 100%;
    }

    .row-item {
        margin-right: -8px;
        margin-left: -8px;
    }

    .product-category a {
        text-decoration: none !important;
        padding: 10px !important;
    }

    .product-category a img {
        max-width: 70px;
    }

    .features-grid {
        padding: 5px;
    }

    .features-grid h4 {
        font-size: 1rem;
    }

    .features-grid h4 span {
        font-size: 0.8rem;
    }

    .vl {
        display: none;
    }
}

/*
@media screen and (max-width:600px) {
    .explore-btn {
        display: none;
    }
}

@media screen and (max-width:600px) {
    .product-category-container {
        max-width: 100%;
    }

    .product-category {
        margin: 0;
    }

    .product-category img {
        max-width: 80px;
    }

    .product-category a {
        font-size: 15px;
    }

    .row-item {
        max-width: 100%;
        align-items: center;
    }

    .row-item .vl {
        height: 80px;
        margin-left: 10px;
        margin-right: 0;
        align-items: center;
    }

    .row-item .vh {
        visibility: hidden;
    }

    .row-item .ml {
        margin-left: 30px;
    }

}*/

/* -- Product Category Ends --*/

/* -- Fan Category Starts --*/
.category-seciton-cont {
    height: 510px;
    background-color: #f2f2f2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.py-sm-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
}

.py-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

.fan-container {
    max-width: 1300px;
}

.h-100 {
    height: 100% !important;
}

.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

}

.align-items-center {
    align-items: center !important;
}

.col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
}

.fan-container h2,
h3,
h4,
strong,
b {
    font-weight: 700;
}

.home-category--cont {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 30px;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.category-seciton-cont p {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
}

.btn {
    padding: 0.375rem 1.5rem;
    font-size: 14px;
    border-radius: 20px;
    text-transform: uppercase;
    font-family: 'helveticaneuelight';
    text-decoration: none;
}

.btn-primary {
    color: #fff;
    background-color: #205170;
    border-color: #556067;
    border-radius: 20px;
}

.mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
}

.mr-10 {
    margin-right: 10px !important;
}

@media screen and (max-width:480px) {
    .category-seciton-cont {
        height: 310px;
    }

    .py-sm-5 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }

    .py-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }

    .fan-container {
        max-width: 470px;
    }

    .row {
        margin-right: -5px;
        margin-left: -5px;
    }

    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1rem !important;
    }

    .fan-container h2,
    h3,
    h4,
    strong,
    b {
        font-weight: 500;
    }

    .fan-container h2 {
        font-size: 1.2rem !important;
    }

    .home-category--cont {
        padding-right: 5px;
        padding-left: 5px;
        margin-left: 15px;
    }

    .justify-content-end {
        justify-content: flex-end !important;
    }

    .category-seciton-cont p {
        font-size: 0.9rem;
    }

    .btn {
        padding: 0.175rem 1.3rem;
        font-size: 12px;
    }
}

@media screen and (max-width:768px) {
    .category-seciton-cont {
        height: 210px;
    }

    .py-sm-5 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }

    .py-3 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }

    .fan-container {
        max-width: 470px;
    }

    .row {
        margin-right: -5px;
        margin-left: -5px;
    }

    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1rem !important;
    }

    .fan-container h2,
    h3,
    h4,
    strong,
    b {
        font-weight: 500;
    }

    .fan-container h2 {
        font-size: 1.2rem !important;
    }

    .home-category--cont {
        padding-right: 5px;
        padding-left: 5px;
        margin-left: 15px;
    }

    .justify-content-end {
        justify-content: flex-end !important;
    }

    .category-seciton-cont p {
        font-size: 0.5rem;
        font-weight: 400
    }

    .btn {
        padding: 0.175rem 1.3rem;
        font-size: 12px;
    }
}

/* -- Fan Category Ends --*/

/* -- Products Start --*/
.fan-list {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.fan-card-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0.5em;
}

.fn-crd {
    width: 300px;
}

.m-5 {
    margin: 15px;
}


.fx-wrp {
    flex-wrap: wrap;
}

@media screen and (max-width:600px) {
    .m-5 {
        margin: 5px;
    }

    .fn-crd {
        width: 200px;
    }
}

/* -- Products End --*/

/* -- Products Details Start --*/

.product-details {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Outfit';
    line-height: 1.5;
}

.card-wrapper {
    max-width: 1100px;
    display: block;
    margin: 0 auto;
}

.card {
    display: grid;
    margin-bottom: 10px;
}

.product-details img {
    width: 100%;
    display: block;
}

.img-display {
    width: 75%;
    overflow: hidden;
    background: #FFF;
    padding: 2em ;
    border-radius: 30px;
    margin-left: 0;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.Image-display{
    width: 75%;
}

.img-showcase {
    width: auto;
    transition: all 0.5s ease;
    text-align: center;
}

.img-showcase img {
    width: 200px;
    height: 300px;
    object-fit: contain;
}
.product-card-title p{
    font-family: 'Outfit';
}
.product-card-title p:nth-child(1){
    color: #00558C;
    font-size: 24px;
    font-weight: 500;
}
.product-card-title p:nth-child(2){
    color:#333;
    font-size: 18px;
    font-weight: 400;
}

.product-card-title{
    width: 100%;
    height: auto;
}

.img-select {
    display: flex;
}

.img-item {
    margin:1rem 0.3rem;
    max-width: 18%;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;
    min-height: 150px;
}
.img-item img{
    border-radius: 10px;
    aspect-ratio: 4/6;
    height: 100%;
    width: 100%;
    object-position: center;
}

.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3) {
    margin-right: 0;
}

.img-item:hover {
    opacity: 0.8;
}

.product-content {
    padding: 2rem 1rem;
    margin-top: 6em;
}

.product-title {
    font-size: 48px;
    font-family: 'Outfit';
    font-weight: 600;
    margin: 1rem 0;
    color: #00558C;
}

.product-link {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    background: #256eff;
    color: #fff;
    padding: 0 0.3rem;
    transition: all 0.5s ease;
}

.product-link:hover {
    opacity: 0.9;
}

.product-rating {
    margin: 1rem 0;
    width: fit-content;
}

.product-price {
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 700;
}

.product-price span {
    font-weight: 400;
}

.last-price span {
    color: #f64749;
    text-decoration: line-through;
}

.new-price span {
    color: #256eff;
}

.product-details h2 {
    text-transform: capitalize;
    padding-bottom: 0.6rem;
    font-family: 'Outfit';
    /* font-size: 24px; */
    color:#00558C;
    font-weight: 500;
}

.product-details p {
    font-size: 0.9rem;
    padding: 0.3rem;
}

.product-details ul {
    margin: 1rem 0;
    font-size: 0.9rem;
}

.product-details ul li {
    margin: 0;
    list-style: none;
    background: url(../images/icons/checkbox_green.png) left center no-repeat;
    /* background: url('https://icons-for-free.com/download-icon-mark+ok+success+tick+icon-1320183418964718934_256.ico') left center no-repeat; */
    background-size: 18px;
    padding-left: 1.7rem;
    margin: 0.4rem 0;
    font-weight: 600;
    font-family: 'Outfit';
    font-size: 18px;
}

.product-details ul li span {
    color: black;
    font-family: 'Outfit';
    font-size: 18px;
    font-weight: 400;
}

.purchase-info {
    display: flex;
    margin: 1.5rem 0;
    align-items: center;
    flex-wrap: wrap;
}
.purchase-info div{
    width: 40%;
    height: 50px;
    margin: 0;
    flex: content;
}

.purchase-info input,
.purchase-info .btn {
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    padding: 0.45rem 0.8rem;
    outline: 0;
    margin-right: 0.2rem;
    margin-bottom: 1rem;
}

.purchase-info input {
    width: 60px;
    border: 1px solid #00558C;
    color: #00558C;
}

.purchase-info .btn {
    cursor: pointer;
    color: #fff;
}

.purchase-info .btn:first-of-type {
    background: #256eff;
}

.purchase-info .btn:last-of-type {
    background: #f64749;
}

.purchase-info .btn:hover {
    opacity: 0.9;
}

.purchase-info label {
    padding: 3px;
    font-family: 'Outfit';
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-right: 0.5em;
}

.social-links {
    display: flex;
    align-items: center;
    width: 60%;
    margin-left: -1em;
    text-align: left;
}

.social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0 0.2rem;
    border-radius: 50%;
    text-decoration: none;
    transition: all 0.5s ease;
}

.social-links a:hover {
    background: #000;
    border-color: transparent;
    color: #fff;
}

.social-links p{
    font-family: 'Outfit';
    font-size: 24px;
    font-weight: 500;
    color: #333;
}
.share-links{
    display: flex;
    align-items: center;
    margin-left:0;
}
.share-links p{
    font-family: 'Outfit';
    font-size: 24px;
    font-weight: 500;
}
.share-links a{
    margin-top: 14px;
}

.query-btn{
    width: 208px;
    height: 53px;
    border: none;
    background: #FCE302;
    border-radius: 20px;
    padding: 15px 15px;
    color: #333;
    font-family: 'Outfit';
    font-size: 18px;
    font-weight: 500;
    margin-right: 1em;
    cursor: pointer;
}
.query-btn:hover{
    background: transparent;
    border: 1px solid #FCE302;
    color: #FCE302;
}
.query-btn a{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}
.details-btn{
    font-family: 'Outfit';
    font-size: 18px;
    font-weight: 500;
    width: 190px;
    height: 53px;
    border-radius: 20px;
    background: transparent;
    border: 1px solid #00558C;
    color: #00558C;
    cursor: pointer;
}
@media screen and (max-width:327px){
    .share-links p{
        margin-left:0;
    }
}
@media screen and (max-width:900px){
    .img-display{
        margin: auto;
    }
    .Image-display{
      width: 80%;
      margin: auto;
    }
    .product-title{
        text-align: center;
    }
    .share-links{
        margin-left: -1.2em;
    }
    .product-content{
        margin: 5em;
    }
    .img-item{
        /* border: 2px solid red; */
        min-width:10%;
        margin: 1rem auto;
        min-height: 100px;
    }
    .img-item img{
        width: 100%;
        aspect-ratio: 6/6;
        height: 100%;
    }
}
@media screen and (max-width:920px){
    .details-btn{
        margin-top: 1em;
    }
}

@media screen and (min-width:900px) {
    .card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }

    .card-wrapper {
        /*height: 100vh;*/
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width:500px){
    .product-content{
        padding: 0 0 0 2em;
        margin:2em;
    }
}
@media screen and (max-width:550px){
    .share-links a{
        margin-left: 0.5em;
    }
    .product-title{
        font-size: 35px;
    }
  
}
@media screen and (max-width:450px){
    .share-links p{
        margin-left:0px;
    }
}
.social-media {
    display: flex;
    flex-direction: row;
}

.social-media .icon {
    width: auto;
    padding: 5px;
    align-items: center;
}

/* -- Products Details End --*/

/* -- Get Details Modal Starts --*/
.modal-img {
    width: 150px;
    display: flex;
}

.paperStyle {
    padding: 0 10px 10px 10px;
    max-width: auto;
    margin: 10px;
}

.btnStyle {
    margin-top: 10;
}

.product-desc-paper h1 {
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #12263a;
    padding: 1rem 1rem 0 1rem;
}

.product-desc-paper p {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 400;
    position: relative;
    color: #12263a;
    padding-left: 1rem;
    padding-right: 1rem;
}
.dialog-content{
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.dialog-content::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width:480px) {
    .modal-img {
        width: 100px;
    }

    .paperStyle {
        padding: 0 5px 5px 5px;
        margin: 5px;
    }

    .btnStyle {
        margin-top: 5;
        border-radius: 15px;
        border: 5px solid red;
    }

    .product-desc-paper h1 {
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0.5rem 0.5rem 0 0.5rem;
    }

    .product-desc-paper p {
        font-size: 0.6rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media screen and (max-width:768px) {
    .modal-img {
        width: 100px;
    }

    .paperStyle {
        padding: 0 5px 5px 5px;
        margin: 5px;
    }

    .btnStyle {
        margin-top: 5;
    }

    .product-desc-paper h1 {
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0.5rem 0.5rem 0 0.5rem;
    }

    .product-desc-paper p {
        font-size: 0.6rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

/* -- Get Details Modal End --*/

/* --- Cinni Starts --*/
.cinni {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
}

.cinni img {
    height: 150px;
}

.cinni p {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 400;
    font-size: 30px;
    color: #b30000;
    padding: 20px;
    text-align: center;
}

.cinni h1 {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 35px;
    text-transform: uppercase;
    padding: 10px;
    text-align: center;
}

@media screen and (max-width:600px) {
    .cinni {
        display: grid;
        padding: 30px 20px;
    }

    .cinni img {
        height: 50px;
    }

    .cinni p {
        font-size: 20px;
        text-align: center;
    }

    .cinni h1 {
        font-size: 30px;
        text-align: center;
    }
}

/* --- Cinni End --*/

/* -- Download Brochure Starts --*/
.download-brochure {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
} 
 
.brochure-name {
    display: grid;
    margin-bottom: 1em;

}

.brochure-name img {
    display: flex;
    align-items: center;
    width: 100px;
    height: 30px;
    mix-blend-mode:darken;
    margin-top: 2em;
}

.brochure-name a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Outfit';
    color: #333;
    background-color: #FCE302;
    border-radius: 20px;
    width: 220px;
    height: 53px;
    padding: 0.8em 2.6em;
    margin-top: 1em;
}

.pdf-ovrflw {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: scroll;
    height: 55.5vh;
    margin-bottom: 5em;
    width: 100%;
    padding: 2em;
}
.pdf-ovrflw::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width:480px) {

    .brochure-name {
        margin-bottom: 5px;
    }

    .small-box {
        max-width: 80%;
    }

    .paper-wd {
        width: 100%;
    }

    .brochure-name img {
        width: 150px;
    }

    .brochure-name a {
       margin-bottom: 3em;
    }

    .pdf-ovrflw {
        height: 70vh;
        width: 100%;
    }
}

@media screen and (max-width:768px) {
    .brochure-name {
        margin-bottom: 5px;
    }

    .small-box {
        max-width: 80%;
    }

    .paper-wd {
        width: 100%;
    }

    .brochure-name img {
        width: 150px;
    }

    .brochure-name a {
        margin-bottom: 3em;
    }

    .pdf-ovrflw {
        height:50vh;
        width: 100%;
    }
}
@media screen and (max-width:1380px){
    .small-box{
        max-width: 100%;
        justify-content: center;  
    }
    .pdf-ovrflw{
        max-width: 100%;
    }
}

@media screen and (max-width:1500px){
    .pdf-ovrflw{
        padding: 0;
    }
}

/*@media screen and (max-width:600px) {
    .paper-wd {
        width: 100%;
    }
}*/

/* -- Download Brochure End --*/


/* --- Custom Padding Starts --*/
.pd-20 {
    padding: 20px;
}

/* --- Custom Padding Ends --*/

/* --- Cinni Fans Starts --*/
.cinni-desc-list {
    list-style-type: none;
    margin: 0;
    padding: 1px 15px;
}

.cinni-desc-list li {
    font-size: 15px;
    font-weight: 600;
}

.cinni-desc-list li span {
    font-size: 15px;
    font-weight: 400;
}

/* --- Cinni Fans Ends --*/

/* -- About US Starts --*/

.about-us {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 20px;
}

.about-us h1 {
    text-transform: uppercase;
    font-size: 50px;
    font-family: 'Popins';
    color: #205170;
}

.about-us p {
    font-size: 30px;
    font-family: 'Brush Script MT', 'Brush Script Std', cursive;
    color: #1f1f2e;
}

.who {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.who .row {
    width: 80%;
    margin: auto;
}

.who .row .title {
    text-align: center;
    margin-top: 50px;
}

.who .row .title h1 {
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #eb6234;
}

.who .row .title p {
    font-size: 18px;
    font-family: sans-serif;
    margin: 30px 0px;
    color: #a3a3a3;
    text-align: justify;
}

.our-team {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.team-section {
    width: 100%;
    padding-bottom: 20px;
}

.team-section .container {
    width: 80%;
    margin: auto;
}

.team-section .container .row {
    width: 60%;
    margin: auto;
}

.team-section .container .row .title {
    text-align: center;
    margin-top: 50px;
}

.team-section .container .row .title h1 {
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #eb6234;
}

.team-section .container .row .title p {
    font-size: 18px;
    font-family: sans-serif;
    margin: 30px 0px;
    color: #a3a3a3;
}

.team-section .container .team-card {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin-top: 50px;
}

.team-section .container .team-card .card {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    border: 1px solid #f0f0f0;
    box-shadow: 1px 1px 5px 1px;
}

.team-section .container .team-card .card:hover {
    background-color: #f3f3f3;
    transition: 0.3s;
}

.team-section .container .team-card .card .image-section img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 6px solid #fff;
}

.team-section .container .team-card .card .card-content {
    width: 100%;
    text-align: center;
}

.team-section .container .team-card .card .card-content h3 {
    font-size: 27px;
    margin-top: 10px;
    letter-spacing: 1.5px;
    color: #639ab8;
}

.team-section .container .team-card .card .card-content h4 {
    font-size: 17px;
    margin-top: 10px;
    letter-spacing: 1.5px;
    color: #6e6e6e;
}

.team-section .container .team-card .card .card-content p {
    font-size: 15px;
    margin-top: 16px 0px;
    line-height: 1.5;
    color: #6e6e6e;
}

.brand-names {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width:480px) {
    .about-us {
        padding: 10px;
    }

    .about-us h1 {
        font-size: 30px;
    }

    .about-us p {
        font-size: 20px;
    }

    .who .row .title {
        margin-top: 20px;
    }

    .who .row .title h1 {
        font-size: 25px;
        letter-spacing: 1px;
    }

    .who .row .title p {
        font-size: 15px;
        margin: 15px 0px;
    }

    .team-section {
        padding-bottom: 10px;
    }

    .team-section .container .row {
        width: 100%;
        margin: auto;
    }

    .team-section .container .row .title {
        margin-top: 20px;
    }

    .team-section .container .row .title h1 {
        font-size: 25px;
        letter-spacing: 1px;
    }

    .team-section .container .row .title p {
        font-size: 15px;
        margin: 15px 0px;
    }

    .team-section .container .team-card {
        grid-template-columns: repeat(2, 1fr);
    }

    .team-section .container .team-card .card {
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        border: 1px solid #f0f0f0;
        box-shadow: 1px 1px 5px 1px;
    }

    .team-section .container .team-card .card:hover {
        background-color: #f3f3f3;
        transition: 0.3s;
    }

    .team-section .container .team-card .card .image-section img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 3px solid #fff;
    }

    .team-section .container .team-card .card .card-content {
        width: 100%;
        text-align: center;
    }

    .team-section .container .team-card .card .card-content h3 {
        font-size: 20px;
        margin-top: 5px;
        letter-spacing: 1.5px;
        color: #639ab8;
    }

    .team-section .container .team-card .card .card-content h4 {
        font-size: 15px;
        margin-top: 10px;
        letter-spacing: 1.5px;
        color: #6e6e6e;
    }

    .team-section .container .team-card .card .card-content p {
        font-size: 12px;
        margin-top: 12px 0px;
        line-height: 1.5;
        color: #6e6e6e;
    }

    .brand-names {
        width: max-content;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .brand-names img {
        width: max-content;
        height: 100px;
    }
}

@media screen and (max-width:768px) {
    .about-us {
        padding: 10px;
    }

    .about-us h1 {
        font-size: 30px;
    }

    .about-us p {
        font-size: 20px;
    }

    .who .row .title {
        margin-top: 20px;
    }

    .who .row .title h1 {
        font-size: 25px;
        letter-spacing: 1px;
    }

    .who .row .title p {
        font-size: 15px;
        margin: 15px 0px;
    }

    .team-section {
        padding-bottom: 10px;
    }

    .team-section .container .row {
        width: 100%;
        margin: auto;
    }

    .team-section .container .row .title {
        margin-top: 20px;
    }

    .team-section .container .row .title h1 {
        font-size: 25px;
        letter-spacing: 1px;
    }

    .team-section .container .row .title p {
        font-size: 15px;
        margin: 15px 0px;
    }

    .team-section .container .team-card {
        grid-template-columns: repeat(2, 1fr);
    }

    .team-section .container .team-card .card {
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        border: 1px solid #f0f0f0;
        box-shadow: 1px 1px 5px 1px;
    }

    .team-section .container .team-card .card:hover {
        background-color: #f3f3f3;
        transition: 0.3s;
    }

    .team-section .container .team-card .card .image-section img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 3px solid #fff;
    }

    .team-section .container .team-card .card .card-content {
        width: 100%;
        text-align: center;
    }

    .team-section .container .team-card .card .card-content h3 {
        font-size: 20px;
        margin-top: 5px;
        letter-spacing: 1.5px;
        color: #639ab8;
    }

    .team-section .container .team-card .card .card-content h4 {
        font-size: 15px;
        margin-top: 10px;
        letter-spacing: 1.5px;
        color: #6e6e6e;
    }

    .team-section .container .team-card .card .card-content p {
        font-size: 12px;
        margin-top: 12px 0px;
        line-height: 1.5;
        color: #6e6e6e;
    }

    .brand-names {
        width: max-content;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .brand-names img {
        width: max-content;
        height: 100px;
    }
}

/* -- About US End --*/


/*----------------  New-ui-changes-starts------------------------------*/

/*---------------Navbar-starts-----------*/
.navlinks{
    border: 0;
    background: transparent;
    cursor: pointer;
}
.navlinks a{
    color:#333;
    font-family: 'Outfit';
    font-size: 18px;
    font-weight: 500;
}

.navlinks a.active{
  color:#AA1318;
}
.navlinks p.active{
    color:#AA1318;
}
.navlinks a:hover{
    color: #AA1318;
}
.navlinks p:hover{
    color:#AA1318;
}
.navlinksV2{
    width: 100%;
    text-align: left;
    padding: 0 0 0 1.5em;
}
.navlinksV2 a{
    font-size: 25px;
}
.navlinksV2 p{
    font-size: 25px;
}
@media screen and (max-width:480px){
   .navlinksV2 a{
    padding: 0;
   }
}

/*---------------Navbar-Ends-----------*/

/*----------------------------Hero-Image-start--------------------------------------------*/
.hero-div-container{
    min-width:100%;
    height: 756px;
}
.heroContainer{
    padding: 8em 6em;
    max-height: 100%;
}
.hero-title{
    color:#fff;
    font-family:'Outfit';
    font-size:24px;
    font-weight:500;
}
.hero-content-container{
    text-align: left;
    min-height:20vh;
    max-width: 825px;
    margin: 0;
    position: sticky;
    margin-top: 1em;
}
.hero-content{
    font-family:'Outfit';
    font-size:64px;
    font-weight:900;
    color:#fff;
    width: 100%;
}
.hero-descp{
    font-family:"Outfit";
    font-size:26px;
    font-weight:350;
    color:#fff;
    max-width: 825px;
    margin: 0;
    margin-top:1em;
}

@media screen and (max-width:768px) {
    .hero-div-container{
        height: 400px;
        width: 100%;
    }
    .heroContainer{
        max-height: 100%;
        padding: 2em 3em;
        width: 100%;
    }
    .hero-content-container{
        min-height: 10vh;
        max-width: 550px;
        margin-top: 2%;
    }
    .hero-content{
        font-size: 30px;
    }
    .hero-title{
        font-size: 20px;
    }
    .hero-descp{
        font-size: 20px;
        margin-top: 2%;
    }
    
}

@media screen and (max-width:480px){
    .hero-div-container{
        height:300px;
        width: 100%;
    }
    .heroContainer{
        padding: 1em 1em;
        max-height: 100%;
    }
    .hero-content-container{
        max-width: 100%;
        min-height: 5vh;
    }
    .hero-descp{
        margin-top:2%;
        font-size: 16px;
    }
    .hero-title{
        font-size: 16px;
    }
    .hero-content{
        font-size: 24px;
    }
  }

/*----------------------------Hero-Image-End--------------------------------------------*/

/*----------------------------about-us-start--------------------------------------------*/

.flexBox{
    display:flex;
    padding:2em 1em;
    width:100%;
    font-family: 'Outfit';
}
.contentBox{
    width: 90%;
    margin: 0;
}
.aboutus-title{
    color:#00558C;
    font-family:"Outfit";
    font-size:24px;
    font-weight:500;
}

.aboutus-heading{
    font-size: 64px;
    font-weight: 600;
    margin-top: 2%;
}
.aboutus-content{
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin-top: 2%;
}

@media screen and (max-width:768px){
    .aboutus-content{
        font-size: 20px;
    }
    .aboutus-heading{
        font-size: 40px;
    }
    .flexBox{
        padding: 2em 1em;
    }
}

@media screen and (max-width:480px){
    .aboutus-content{
        font-size: 17px;
    }
    .aboutus-heading{
        font-size: 30px;
    }
}

/*----------------------------about-us-end--------------------------------------------*/

/*----------------------------our-product-start--------------------------------------------*/
.nav-btn{
    width:243px;
    height:55px;
    padding:1em 1em;
    background:#FCE302;
    border-radius:20px;
}
.nav-btn:hover{
    background: transparent;
    border: 1px solid #FCE302;
    color: #FCE302;
}
.nav-btn a{
    text-decoration:none;
    font-family:Outfit;
    font-size:18px;
    font-weight:500;
}
.nav-btn a:hover{
    color: #FCE302;
}
.our-product-list-container{
    display:flex;
    flex-flow:row wrap;
    width:100%;
    padding:2em 0em;
}
.product-statistics-box{
    width:250px;
    height:15vh;
    font-family: 'Outfit';
}

.product-statistics-box h3{
    font-family: inherit;
    font-size: 42px;
    font-weight: 800;
    background:linear-gradient(to bottom, #00558c, #00558c,#00558c,#fce30f, #fce302);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


@media screen and (max-width:768px){
    .our-product-list-container{
        max-width: 100%;
        flex-flow: row wrap;
        gap: 2em;
        /* padding: 0 25%; */
    }
    .product-statistics-box{
        width: 110px;
        max-height: 100px;
        flex: 1 50%;
        padding: 0 0;
        margin:0;
    }
    .our-product-list-container div:nth-child(1){
        flex-basis: 120px;
    }
    .our-product-list-container div:nth-child(2){
        flex-basis: 120px;
    }
    .our-product-list-container div:nth-child(3){
        flex-basis: 100px;
    }
    .our-product-list-container div:nth-child(4){
        flex-basis: 100px;
      
    }
    .product-statistics-box p{
        font-size: 20px;
    }
    .product-statistics-box h3{
        font-size: 35px;
    }
   
}

@media screen and (max-width:538px){
    .our-product-list-container{
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: center;
    }
    .product-statistics-box p{
        font-size: 14px;
    }
    .product-statistics-box h3{
        font-size: 25px;
    }
   
    .product-statistics-box{
        max-width: 60px;
        max-height: 80px;
        padding: 0 0;
    }
}
@media screen and (max-width:368px){
    .product-statistics-box{
        /* border: 1px solid red; */
        max-width: 50px;
        margin: 0 5px 0 0;
    }
}   

/*----------------------------our-product-end--------------------------------------------*/

/*----------------------------our-team-start--------------------------------------------*/

.svg-container{
    max-height: 100%;
}
.team-card img{
    width:325px;
    height:250px;
    border-radius: 20px;
}
.green-arrow img{
    width:350px;
    margin-left:20%;
    vertical-align:30vh;
}

@media screen and (max-width:768px){
    .team-card img{
       width: 250px;
       height: 200px;
    }
    .team-card p:nth-child(2){
        font-size: 30px;
    }
    .team-card p:nth-child(3){
        font-size: 22px;
    }
    .team-card p:nth-child(4){
        font-size: 18px;
    }
    .green-arrow{
        position: relative;
    }
    .green-arrow img{
       width: 220px;
       position: absolute;
       top: -40em;
       left: 8em;
    }
   
}
@media screen and (max-width:580px){
    .team-card img{
        width: 140px;
        height: 120px;
     }
     .team-card p:nth-child(2){
         font-size: 18px;
     }
     .team-card p:nth-child(3){
         font-size: 16px;
     }
     .team-card p:nth-child(4){
         font-size: 14px;
     }
     .green-arrow{
        position: relative;
    }
    .green-arrow img{
       width: 180px;
       position: absolute;
       top: -30em;
       left: 4em;
    }
}
@media screen and (max-width:480px){
    .green-arrow img{
        width:140px;
        left: 2.5em;
     }
}
/*----------------------------our-team-end--------------------------------------------*/


/*----------------------------contact-us-start--------------------------------------------*/

.contact-us-container{
    display: flex;
    padding:2em 0;
    margin-top: 5%;
    flex-wrap:wrap;
    width: 100%;
    align-items: flex-start;
}

.contact-us-contentBox{
    /* width: 50%; */
    width: 650px;
    min-height: 730px;
    /* border: 1px solid red; */
}
.contact-us-formBox{
    width: 80vb;
    min-height: 500px;
}
.contact-us-content{
    display: flex;
    gap: 10%;
}
.text-area::placeholder{
  font-size: 10px;
}

.inputField{
    font-family: 'Outfit';
    font-size: 10px;
    font-weight: 400;
    color: #333;
    width:40em;
    height:40px;
    background-color: #FEFBE1;
    border:none;
    padding: 1em 2em;
    margin-top: 1em;
}
.inputFieldTwo{
    font-family: 'Outfit';
    font-size: 10px;
    font-weight: 400;
    color: #333;
    height: 40px;
    width:19em;
    background-color: #FEFBE1;
    border:none;
    padding: 1em 2em;
    margin-top: 1em;
    
}
.inputTextArea{
    font-family: 'Outfit';
    font-size: 10px;
    font-weight: 400;
    color: #333;
    width:40em;
    height:81px;
    background-color: #FEFBE1;
    border:none;
    padding: 1em 2em;
    margin-top: 1em;
}

@media screen and (max-width:1200px){
    .inputFieldTwo{
        height: 40px;
        width:40em;
    }
}

@media screen and (max-width:768px){
    .contact-us-content p:nth-child(2){
        font-size: 40px;
    }
    .contact-us-content p:nth-child(3){
        font-size: 20px;
    }
    .contact-us-contentBox{
        min-height: 100px;
    }
    .contact-us-formBox{
        margin-top: 2%;
        padding: 1em 2em;
    }
}

@media screen and (max-width:480px){
    .contact-us-content p:nth-child(2){
        font-size: 30px;
    }
    .contact-us-content p:nth-child(3){
        font-size: 17px;
    }
    .contact-us-formBox button{
        width: 180px;
    }
}
/*----------------------------contact-us-end--------------------------------------------*/

.brands-container{
    min-height: 150px;
    width: 100%;
}
.brands-flexBox{
    width:100%;
    display: flex;
}
.brands-box{
    width:200px;
}
.brands-box img{
    width: 100%;
    mix-blend-mode: multiply;
}
@media screen and (max-width:768px) {
    .brands-container{
        height: 200px;
    }

}
@media screen and (max-width:580px) {
    .brands-container{
        height: 200px;
        width: 100%;
    }
    .brands-container p{
        font-size: 22px;
    }
    .brands-flexBox{
        justify-content: center;
        align-items: center;
        margin-top: 5%;
    }
    .brands-box img{
        width: 100px;
        mix-blend-mode: multiply;
    }
}
@media screen and (max-width:480px){
    .brands-box img{
        width: 90px;
        mix-blend-mode: multiply;
    }
}
/*----------------------------footer-start--------------------------------------------*/

  .footer1 {
    background: radial-gradient(45.52% 45.52% at 50.00% 50.00%, rgba(155, 217, 245, 0.30) 0%, rgba(107, 174, 223, 0.06) 100%);
  }
  
  .footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 2%;
    max-width: 100%;
  }
  .logo{
    text-align: center;
  }
  
  .logo img {
    width: 125px; /* Set the desired width for your logo */
    vertical-align:0vh;
    mix-blend-mode: multiply;
  } 
  .footer-links img{
    vertical-align: 15vh;
    margin-top: 1em;
    margin-left: 1em;
  }
  .footer-links a img{
    width: 24px;
  }
  
  .menus {
    height: 200px;
    max-width:20%;
    text-align: left;
  }
  .menu3{
    max-width: 40%;
  }
  
  .menu-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Roboto';
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  a {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #333;
    text-decoration: none;
  }

  .cinni-logo{
    width: 100%;
  } 
  .cinni-logo img{
    width: 125px;
    vertical-align: 150px;
    mix-blend-mode: multiply;
    float: right;
  }

.contact-us-address{
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 5%;
}
.contact-us-address1{
    display: flex;
    align-items: flex-start;
    /* width: 100%; */
    gap: 5%;
}

.contact-us-address div:nth-child(1){
    width:5%;
    margin-top: 2px;
}
.contact-us-address div:nth-child(2){
    width:90%;
}
.contact-us-address img{
    mix-blend-mode: multiply;
}

.contact-us-address1 div:nth-child(1){
    width: 5%;
}

.footer-email{
    width: 90%;
    margin: 0;
}

  
  /* Media Query for Responsive Footer */

  @media screen and (max-width: 768px) {
    .footer-content {
      flex-wrap: wrap;
    }
  
    .footer-links img{
        vertical-align: 19vh;
    }
    .logo{
        min-width: 100%;
        max-height: 100px;
        text-align: center;
    }
    .logo img {
        width: 90px; /* Set the desired width for your logo */
      } 
      .footer-links a img{
        vertical-align: 15vh;
        margin-top: 1em;
        margin-left: 10px;
        width: 18px;
      }
      .menus{
        min-height: 250px;
        max-width: 50%;
        margin: auto;
        margin-top: 5%;
      }
      .contact-us-address{
        gap: 8%;
      }
      .contact-us-address1{
        gap: 8%;
      }
      .cinni-logo{
        width: 100%;
        margin: auto;
        text-align: center;
      }
      .cinni-logo img{
        float: none;
        vertical-align: 5px;
      }
     
  }

  @media screen and (max-width:480px){
    .footer-email>li a{
        line-break:anywhere;
    }
    .cinni-logo{
        width: 100%;
        margin: auto;
        text-align: center;
    }
    .cinni-logo img{
        width: 100px;
    }
    .footer-content{
        padding: 0 1em;
    }
    .menus{
        padding: 0 0.5em;
    }
    .menu-title{
        font-size: 14px;
    }
    .menus li a{
        font-size: 12px;
    }
    .logo img{
        /* border: 1px solid red; */
        width: 70px;
    }
    .footer-links a img{
        width: 15px;
    }
  }
 
  /*----------------------------footer-end--------------------------------------------*/


    /*----------------------------products-page-start--------------------------------------------*/

  .see-more-btn {
    text-align: end;
    padding: 1em 5em;
 }
.see-more-btn button{
    background-color: #FCE302;
    border-radius: 20px;
    border: none;
    color:#000;
    font-family: 'Outfit';
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    width: 165px;
    height: 53px;
}
.see-more-btn p{
    font-family: inherit;
}
.exp-btn{
    text-transform: uppercase;
}
 .fan-heading p{
   font-family: 'Outfit';
   font-size: 48px;
   font-weight: 600;
   padding: 0.5em 1.2em;
   color:#00558C;
}

@media screen and (max-width:900px){
    .see-more-btn{
        text-align: center;
    }
    .fan-heading p{
        font-size: 35px;
        text-align: center;
    }
}

